<template>
  <v-container fluid class="pa-1" v-if="user_info.role <= 1">
    <v-row no-gutters>
      <v-col xl="6" lg="6" md="6" sm="12" cols="12">
        <listEditForm
          class="ma-1"
          topTitle="หลักสูตร / ห้องเรียน"
          plusTitle="เพิ่มห้อง"
          label="หลักสูตร / ห้องเรียน"
          type="1"
        />
      </v-col>
      <v-col xl="6" lg="6" md="6" sm="12" cols="12">
        <listEditForm
          class="ma-1"
          topTitle="อาจารย์ผู้สอน"
          plusTitle="เพิ่มอาจารย์"
          label="ชื่อ สกุล อาจารย์ผู้สอน"
          type="3"
        />
      </v-col>
      <v-col xl="6" lg="6" md="6" sm="12" cols="12">
        <listEditForm
          class="ma-1"
          topTitle="สถานะ"
          plusTitle="เพิ่มสถานะ"
          label="รายละเอียดสถานะ"
          type="2"
        />
      </v-col>
      <v-col xl="6" lg="6" md="6" sm="12" cols="12">
        <listEditForm
          class="ma-1"
          topTitle="รู้จักมูลนิธิจาก"
          plusTitle="เพิ่มรายการ"
          label="รายละเอียดการรู้จักมูลนิธิ"
          type="4"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import listEditForm from "@/components/listEditForm";
export default {
  name: "manage",
  components: {
    listEditForm,
  },
  computed: {
    ...mapState(["user_info"]),
  },
};
</script>

<style></style>
