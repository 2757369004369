<template>
  <v-data-table
    :headers="headers"
    :items="listArr"
    sort-by="calories"
    :hide-default-footer="false"
    class="elevation-1"
    dense
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{ topTitle }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              {{ plusTitle }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.desc1"
                      :label="label"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="type == 1" cols="12">
                    <v-text-field
                      v-model="editedItem.desc1"
                      label="หมายเลขห้องเรียน"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.desc2"
                      label="ห้องย่อย"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="type == 2" cols="12"></v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.ordernum"
                      label="ลำดับ"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">ยกเลิก</v-btn>
              <v-btn color="blue darken-1" text @click="save">บันทึก</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">แน่ใจที่จะลบหรือไม่</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">
                ยกเลิก
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                ลบ
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
      <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
    </template>
    <template v-slot:no-data>
      <!-- <v-btn color="primary" @click="initialize"> reload </v-btn> -->
    </template>
  </v-data-table>
</template>
<script>
import axios from "axios";
const token = localStorage.getItem("token");
axios.defaults.headers.common = {
  Authorization: `Bearer ${token}`,
  Accept: "application/json",
};

export default {
  props: ["topTitle", "plusTitle", "label", "type"],
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [],
    listArr: [],
    editedIndex: -1,
    editedItem: {
      type: "",
      desc1: "",
      desc2: "",
      desc3: "",
      ordernum: "",
    },
    defaultItem: {
      type: "",
      desc1: "",
      desc2: "",
      desc3: "",
      ordernum: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "เพิ่มรายการใหม่" : "แก้ไขรายละเอียด";
    },
    list_url: function () {
      return this.$store.state.apiurl + "/api/list";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  mounted() {
    if (this.type == "1") {
      this.headers = [
        {
          text: "เลขห้อง",
          align: "start",
          sortable: true,
          value: "desc1",
        },
        {
          text: "ห้องย่อย",
          align: "start",
          sortable: false,
          value: "desc2",
        },
        {
          text: "ลำดับ",
          align: "start",
          sortable: false,
          value: "ordernum",
        },
        { text: "ดำเนินการ", value: "actions", sortable: false },
      ];
    } else if (this.type == "2") {
      this.headers = [
        {
          text: "รายละเอียด",
          align: "start",
          sortable: false,
          value: "desc1",
        },
        {
          text: "ลำดับ",
          align: "start",
          sortable: false,
          value: "ordernum",
        },
        { text: "ดำเนินการ", value: "actions", sortable: false },
      ];
    } else if (this.type == "3") {
      this.headers = [
        {
          text: "ชื่อ - สกุล",
          align: "start",
          sortable: false,
          value: "desc1",
        },
        {
          text: "ลำดับ",
          align: "start",
          sortable: false,
          value: "ordernum",
        },
        { text: "ดำเนินการ", value: "actions", sortable: false },
      ];
    } else if (this.type == "4") {
      this.headers = [
        {
          text: "รายละเอียด",
          align: "start",
          sortable: false,
          value: "desc1",
        },
        {
          text: "ลำดับ",
          align: "start",
          sortable: false,
          value: "ordernum",
        },
        { text: "ดำเนินการ", value: "actions", sortable: false },
      ];
    }
  },
  methods: {
    async initialize() {
      if (this.type) {
        await this.getListSql(this.type);
        this.editedItem.type = await this.type;
      }
      // this.listArr = [
      //   {
      //     desc1: "หลักสูตร 1 ห้อง 1/1",
      //   },
      // ];
    },
    async getListSql(type) {
      var { data } = await axios.get(this.list_url, {
        params: {
          type: type,
        },
      });
      this.listArr = await data;
      // console.log(this.listArr);
    },

    editItem(item) {
      this.editedIndex = this.listArr.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.listArr.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      // this.listArr.splice(this.editedIndex, 1);
      await axios.post(this.list_url, {
        action: "delete",
        type: this.type,
        data: this.editedItem,
        token: localStorage.getItem("token"),
      });
      await this.initialize();
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        await axios.post(
          this.list_url + "/" + this.editedItem.id + "?_method=PUT",
          {
            id: this.editedItem.id,
            type: this.type,
            ordernum: this.editedItem.ordernum,
            desc1: this.editedItem.desc1,
            desc2: this.editedItem.desc2,
            desc3: this.editedItem.desc3,
          }
        );
        await this.$store.dispatch("init_class_room_full", { type: 1 });
        await this.$store.dispatch("init_status_full", { type: 2 });
        await this.$store.dispatch("init_teacher_full", { type: 3 });
        await this.$store.dispatch("init_know_from_full", { type: 4 });
      } else {
        await axios.post(this.list_url, {
          id: this.editedItem.id,
          type: this.editedItem.type,
          ordernum: this.editedItem.ordernum,
          desc1: this.editedItem.desc1,
          desc2: this.editedItem.desc2,
          desc3: this.editedItem.desc3,
        });
        await this.$store.dispatch("init_class_room_full", { type: 1 });
        await this.$store.dispatch("init_status_full", { type: 2 });
        await this.$store.dispatch("init_teacher_full", { type: 3 });
        await this.$store.dispatch("init_know_from_full", { type: 4 });
      }
      await this.initialize();
      await this.close();
    },
  },
};
</script>
